<template>
	<div id="asset">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>总务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">资产管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="tabTap(index)" :class="index == tabIndex ? 'active' : ''" v-for="(item, index) in meun" :key="index">{{ item }}</div>
				</div>
			</div>

			<div class="content">
				<div class="u-f">
					<div class="left">
						<div class="add u-f-justify el-icon-plus" @click="addtype(0)"></div>
						<div class="building-list">
							<div v-for="items in assetTypeList" :label="items.name" :key="items.id">
								<div class="u-f-item item u-f-jsb" @mouseover="items.isbtn = true" @mouseleave="items.isbtn = false">
									<div class="u-f-item">
										<div style="width: 20px;">
											<i
												v-if="items.children.length"
												@click="items.isShow = !items.isShow"
												:class="items.isShow ? 'el-icon-caret-bottom' : 'el-icon-caret-right'"
											></i>
										</div>
										<div>{{ items.name }}</div>
									</div>
									<div class="u-f-item btn" v-if="items.isbtn">
										<i class="el-icon-plus u-f-justify" @click.prevent @click.stop="addtype(items.id)"></i>
										<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editShow(items)"></i>
										<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delPlace(items.id)"></i>
									</div>
								</div>
								<div class="floor-list" v-if="items.children.length && items.isShow">
									<div
										class="floor-item u-f-item u-f-jsb"
										@mouseover="item.isbtn = true"
										@mouseleave="item.isbtn = false"
										:class="item.id == sid ? 'active' : ''"
										v-for="(item, i) in items.children"
										:key="i"
										@click="tapType(item.id)"
									>
										<div>{{ item.name }}</div>
										<div class="u-f-item btn" v-if="item.isbtn">
											<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editShow(item)"></i>
											<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delPlace(item.id)"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class=" right">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total }}条数据
								<!-- <span style="color: #007AFF;">已选择0条</span> -->
							</div>
							<div class="u-f-item">
								<!-- <el-input placeholder="请输入名称搜索" v-model="sousuo" size="small" style="margin-right: 10px;">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input> -->
								<!-- <el-button size="small" type="primary">搜索</el-button> -->
								<el-button size="small" @click="storage = true">资产入库</el-button>
								<el-button size="small">批量更新</el-button>
								<el-button size="small">批量录入</el-button>
								<el-button size="small" type="primary">导出</el-button>
							</div>
						</div>
						<el-table :data="assetList" height="80%" style="width: 100%;border: 1px solid #EEEEEE;">
							<el-table-column type="index" label="序号" align="center"></el-table-column>
							<el-table-column prop="sn" label="资产编号" align="center"></el-table-column>
							<el-table-column prop="name" label="资产名称" align="center" column-key="schoolplace_name"></el-table-column>
							<el-table-column prop="schoolplacefloor_name" align="center" label="分类">
								<template slot-scope="scope">
									<div>{{ scope.row.category_name }}/{{ scope.row.categorys_name }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="school_place_room_name" label="位置" align="center"></el-table-column>
							<el-table-column prop="storagetime_text" align="center" label="入库时间"></el-table-column>
							<el-table-column label="状态" prop="status_text" width="100" align="center">
								<template slot-scope="scope">
									<el-tag v-if="scope.row.status_text">{{ scope.row.status_text }}</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="number" label="库存" width="100" align="center">
								<template slot-scope="scope">
									<div>{{ scope.row.number }}{{ scope.row.units }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="money" label="金额" width="100" align="center"></el-table-column>
							<el-table-column label="操作" width="120" align="center">
								<template slot-scope="scope">
									<el-button @click="openDialog(scope.row)" type="text" size="small">编辑</el-button>
									<!-- <el-button @click="del_room(scope.row.id)" type="text" size="small">流转记录</el-button> -->
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
				<!-- 添加分类 -->
				<el-dialog title="添加分类" :visible.sync="addVisible" width="500px" :before-close="handleClose">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal">
								<span>*</span>
								分类名称
							</div>
							<el-input v-model="name" placeholder="请输入分类名称" style="width: 70%;"></el-input>
						</div>
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="closeDialog" size="small">取 消</el-button>
						<el-button type="primary" size="small" @click="submitaddtype">确 定</el-button>
					</span>
				</el-dialog>
				<!-- 编辑分类 -->
				<el-dialog title="编辑分类" :visible.sync="editVisible" width="500px" :before-close="handleClose">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal">
								<span>*</span>
								分类名称
							</div>
							<el-input v-model="name" placeholder="请输入分类名称" style="width: 70%;"></el-input>
						</div>
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="closeDialog" size="small">取 消</el-button>
						<el-button type="primary" size="small" @click="submitaddedit">确 定</el-button>
					</span>
				</el-dialog>
				<!-- 资产入库 -->
				<el-dialog title="资产入库" :visible.sync="storage" width="500px" :before-close="handleClose">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								资产名称
							</div>
							<el-input v-model="assetname" placeholder="请输入资产名称" style="width: 75%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								资产分类
							</div>
							<el-cascader v-model="asseTypeId" :props="props" :options="assetTypeList" @change="typeChange" style="width: 75%;"></el-cascader>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								位置
							</div>
							<el-select v-model="roomid" placeholder="请选择位置" style="width: 75%;" filterable >
								<el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								入库日期
							</div>
							<el-date-picker value-format="yyyy-MM-dd" v-model="storagetime" type="date" placeholder="选择日期" style="width: 40%;"></el-date-picker>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								物品数量
							</div>
							<el-input-number v-model="number"></el-input-number>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								物品单位
							</div>
							<el-input v-model="units" placeholder="请输入物品单位" style="width: 40%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">金额</div>
							<el-input v-model="money" style="width: 40%;"><span slot="suffix" style="color: #000000;line-height: 40px;margin-right: 10px;">元</span></el-input>
						</div>
						<!-- <div class="u-f input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">备注</div>
							<el-input type="textarea" :rows="4" placeholder="请输入备注" v-model="textarea" style="width: 75%;"></el-input>
						</div> -->
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="closeDialog" size="small">取 消</el-button>
						<el-button type="primary" size="small" @click="submit">确 定</el-button>
					</span>
				</el-dialog>
				<!-- 编辑资产 -->
				<el-dialog title="编辑资产" :visible.sync="editstorage" width="500px" :before-close="handleClose">
					<div class="edit-box">
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								资产名称
							</div>
							<el-input v-model="assetname" placeholder="请输入资产名称" style="width: 75%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								资产分类
							</div>
							<el-cascader v-model="asseTypeId" :props="props" :options="assetTypeList" @change="typeChange" style="width: 75%;"></el-cascader>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								位置
							</div>
							<el-select v-model="roomid" placeholder="请选择位置" style="width: 75%;" filterable >
								<el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								入库日期
							</div>
							<el-date-picker value-format="yyyy-MM-dd" v-model="storagetime" type="date" placeholder="选择日期" style="width: 40%;"></el-date-picker>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								物品数量
							</div>
							<el-input-number v-model="number"></el-input-number>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">
								<span>*</span>
								物品单位
							</div>
							<el-input v-model="units" placeholder="请输入物品单位" style="width: 40%;"></el-input>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal" style="text-align: right;width: 80px;">金额</div>
							<el-input v-model="money" style="width: 40%;"><span slot="suffix" style="color: #000000;line-height: 40px;margin-right: 10px;">元</span></el-input>
						</div>
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="closeDialog" size="small">取 消</el-button>
						<el-button type="primary" size="small" @click="editSubmit">确 定</el-button>
					</span>
				</el-dialog>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			meun: ['固定资产管理', '耗材管理'],
			tabIndex: 0,
			storage: false,
			assetTypeList: [],
			addVisible: false,
			editVisible: false,
			name: '',
			pid: 0,
			typeId: '',
			total: 0,
			assetList: [],
			sousuo: '',
			page: 1,
			limit: 10,
			assetname: '',
			asseTypeId: [],
			storagetime: '',
			number: '',
			money: 0,
			props:{
				value:'id',
				label:'name'
			},
			roomList:[],
			roomid:'',
			category_id:'',
			category_ids:'',
			units:'',
			editstorage:false,
			id:'',
			sid:''
		};
	},
	mounted() {
		this.getAssetTypeList();
		this.getAssetList();
		this.getPlaceRoomList()
	},
	methods: {
		tapType(id){
			this.sid = id;
			this.page = 1;
			this.getAssetList();
		},
		closeDialog(){
			this.addVisible = false;
			this.editVisible = false;
			this.storage = false;
			this.editstorage = false;
			this.reset();
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getAssetList()
		},
		tabTap(index) {
			if(index==1){
				this.$router.push('/supplies');
			}
			this.tabIndex = index;
		},
		handleClose(done) {
			done();
			this.reset();
		},
		typeChange(e){
			this.category_id = e[0];
			this.category_ids = e[1];
		},
		// 获取房间列表
		getPlaceRoomList() {
			this.$api.setting.getPlaceRoomList({}).then(res => {
				if (res.data.code == 1) {
					this.roomList = res.data.data.room.rows;
				}
			});
		},
		// 获取分类列表
		getAssetTypeList() {
			this.$api.asset.getAssetTypeList({filter: JSON.stringify({mold:2})}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					for (let i in data) {
						data[i].isShow = false;
						data[i].isbtn = false;
						for (let j in data[i].children) {
							data[i].children[j].checked = true;
							data[i].children[j].isbtn = false;
						}
					}
					this.assetTypeList = data;
				}
			});
		},
		// 添加分类
		addtype(pid) {
			this.pid = pid;
			this.name = '';
			this.addVisible = true;
		},
		submitaddtype() {
			let data = {
				name: this.name,
				pid: this.pid,
				mold: 2
			};
			this.$api.asset.addAssetType(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.addVisible = false;
					this.closeDialog();
					this.getAssetTypeList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 编辑分类弹窗
		editShow(item) {
			this.pid = item.pid;
			this.name = item.name;
			this.typeId = item.id;
			this.editVisible = true;
		},
		submitaddedit() {
			let data = {
				name: this.name,
				pid: this.pid,
				mold: 2,
				id:this.typeId
			};
			this.$api.asset.editAssetType(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.editVisible = false;
					this.closeDialog();
					this.getAssetTypeList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 删除分类
		delPlace(id) {
			let _this = this;
			_this
				.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				})
				.then(() => {
					_this.$api.asset
						.delAssetType({
							id: id
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.getAssetTypeList();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
		},
		// 获取资产列表
		getAssetList() {
			let data = {
				mold:2
			}
			if(this.sid){data.category_ids = this.sid}
			this.$api.asset.getAssetList({
				page:this.page,
				limit:this.limit,
				filter: JSON.stringify(data)
			}).then(res => {
				if (res.data.code == 1) {
					this.assetList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			});
		},
		// 资产入库
		submit(){
			if(this.assetname==''||!this.assetname){return this.$message.error('请输入资产名称');}
			if(this.category_id==''||!this.category_id){return this.$message.error('请选择分类');}
			if(this.roomid==''||!this.roomid){return this.$message.error('请选择位置');}
			if(this.storagetime==''||!this.storagetime){return this.$message.error('请选择入库时间');}
			if(this.number==''||!this.number){return this.$message.error('请输入物品数量');}
			if(this.units==''||!this.units){return this.$message.error('请输入物品单位');}
			let data = {
				name:this.assetname,
				units:this.units,	
				origin:1,
				storagetime:this.storagetime,
				mold:2,
				number:this.number,
				money:this.money,	
				category_id:this.category_id,
				category_ids:this.category_ids,
				school_place_room_id:this.roomid
			}
			this.$api.asset.assetStroage(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.storage = false;
					this.closeDialog();
					this.getAssetList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
			
		},
		reset(){
			this.name = '';
			this.pid = '';
			this.assetname = '';
			this.storagetime = '';
			this.units = '';
			this.number = '';
			this.money = '';
			this.category_id = '';
			this.category_ids = '';
			this.asseTypeId = [];
			this.roomid = '';
		},
		// 编辑资产
		openDialog(e){
			this.id = e.id;
			this.assetname = e.name;
			this.storagetime = e.storagetime_text;
			this.units = e.units;
			this.number = e.number;
			this.money = e.money;
			this.category_id = e.category_id;
			this.category_ids = e.category_ids;
			this.asseTypeId[0] = this.category_id;
			this.asseTypeId[1] = this.category_ids;
			this.roomid = e.school_place_room_id;
			this.editstorage = true;
		},
		editSubmit(){
			if(this.assetname==''||!this.assetname){return this.$message.error('请输入资产名称');}
			if(this.category_id==''||!this.category_id){return this.$message.error('请选择分类');}
			if(this.roomid==''||!this.roomid){return this.$message.error('请选择位置');}
			if(this.storagetime==''||!this.storagetime){return this.$message.error('请选择入库时间');}
			if(this.number==''||!this.number){return this.$message.error('请输入物品数量');}
			if(this.units==''||!this.units){return this.$message.error('请输入物品单位');}
			let data = {
				id:this.id,
				name:this.assetname,
				units:this.units,	
				origin:1,
				storagetime:this.storagetime,
				mold:2,
				number:this.number,
				money:this.money,	
				category_id:this.category_id,
				category_ids:this.category_ids,
				school_place_room_id:this.roomid
			}
			this.$api.asset.assetEdit(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('修改成功');
					this.getAssetList();
					this.closeDialog();
				}else{
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss">
#asset {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.72vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						cursor: pointer;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 78vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
